import styled, { css } from 'styled-components';

import { Colors } from '../../../styles/colors';
import { Plan } from '../../../generated/types';
import vipLottie from '../../../assets/lotties/vip-pass.json';
import rocketLottie from '../../../assets/lotties/rocket.json';

import { Container } from './Pricing.styles';
import { PrimaryButton, SecondaryButton } from '../../atoms/Button';
import { HRSection } from '../../atoms/HRSection';
import { BulletList } from '../../atoms/BulletList';
import { CheckIcon, ChevronRightIcon, CreditCardIcon, LawIcon } from '../../atoms/Icons';
import { Lottie } from '../../atoms/Lottie';

export const Pricing = ({
  selectedPlan,
  handlePlanSelect,
  hadTrial = false,
}: {
  selectedPlan?: Plan | null;
  handlePlanSelect: (plan: Plan) => void;
  hadTrial?: boolean;
}) => {
  const hasSelectedPlan = !!selectedPlan;
  return (
    <Container>
      <Columns>
        {!hadTrial ? (
          <Column
            $hasSelectedPlan={hasSelectedPlan}
            onClick={() => handlePlanSelect(Plan.Trial)}
            $isSelected={selectedPlan === Plan.Trial}
          >
            <Header>
              <Title>
                <IconWrapper>
                  <Lottie key="Collaborate" autoplay={true} animationData={vipLottie} />
                </IconWrapper>
                7 Days Trial
              </Title>
            </Header>
            <HRSection />
            <Price>
              <PriceCurrency>£</PriceCurrency>
              <PriceAmount>1.99</PriceAmount>
            </Price>
            <Action>
              <PrimaryButton
                onClick={() => handlePlanSelect(Plan.Trial)}
                iconRight={<ChevronRightIcon color={Colors.White} />}
              >
                Try Now
              </PrimaryButton>
              <Legal>After 7 days, it renews to £9.99 billed monthly.</Legal>
            </Action>
            <HRSection />
            <Features>
              <BulletList $hasBullets={false}>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  Unlimited Boards
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  Unlimited Job Applications
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  Unlimited Resume Downloads
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  Unlimited Events, Contacts
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  Unlimited Tasks and Notes
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  Kanban and Calendar Views
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  Smart Tasks Manager
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  Import Job Post from URL or description
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  AI generated Resumes
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  AI generated Cover letters
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  AI generated Emails
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  AI Assistant
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  Daily and Weekly Email Reports
                </li>
                {/* <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Matching Job Applications - <i>Coming Soon</i>
              </li> */}
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  Instant Notifications
                </li>
                <li>
                  <CheckIcon size={1.3} color={Colors.Primary} />
                  No adverts
                </li>
              </BulletList>
            </Features>
          </Column>
        ) : null}

        <Column
          $hasSelectedPlan={hasSelectedPlan}
          onClick={() => handlePlanSelect(Plan.Monthly)}
          $isSelected={selectedPlan === Plan.Monthly}
        >
          <Header>
            <Title>
              <IconWrapper>
                <Lottie key="Collaborate" autoplay={true} animationData={vipLottie} />
              </IconWrapper>
              Monthly
            </Title>
          </Header>
          <HRSection />
          <Price>
            <PriceCurrency>£</PriceCurrency>
            <PriceAmount>9.99</PriceAmount> / month
          </Price>
          <Action>
            <PrimaryButton
              onClick={() => handlePlanSelect(Plan.Monthly)}
              iconRight={<ChevronRightIcon color={Colors.White} />}
            >
              Upgrade Now
            </PrimaryButton>
            <Legal>
              <LawIcon size={0.8} />
              14-day money back guarantee.
            </Legal>
          </Action>
          <HRSection />
          <Features>
            <BulletList $hasBullets={false}>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Unlimited Boards
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Unlimited Job Applications
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Unlimited Resume Downloads
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Unlimited Events, Contacts
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Unlimited Tasks and Notes
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Kanban and Calendar Views
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Smart Tasks Manager
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Import Job Post from URL or description
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                AI generated Resumes
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                AI generated Cover letters
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                AI generated Emails
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                AI Assistant
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Daily and Weekly Email Reports
              </li>
              {/* <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Matching Job Applications - <i>Coming Soon</i>
              </li> */}
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Instant Notifications
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                No adverts
              </li>
            </BulletList>
          </Features>
        </Column>
        <Column
          $hasSelectedPlan={hasSelectedPlan}
          onClick={() => handlePlanSelect(Plan.Quarterly)}
          $isSelected={selectedPlan === Plan.Quarterly}
        >
          <Header>
            <Title>
              <IconWrapper>
                <Lottie key="Collaborate" autoplay={true} animationData={vipLottie} />
              </IconWrapper>
              Quarterly
            </Title>
            <SubTitle>Pay £20.97 every 3 months</SubTitle>
          </Header>
          <HRSection />
          <Price>
            <PriceCurrency>£</PriceCurrency>
            <PriceAmount>6.99</PriceAmount> / month
          </Price>
          <Action>
            <PrimaryButton
              onClick={() => handlePlanSelect(Plan.Quarterly)}
              iconRight={<ChevronRightIcon color={Colors.White} />}
            >
              Upgrade Now
            </PrimaryButton>
            <Legal>
              <LawIcon size={0.8} />
              14-day money back guarantee.
            </Legal>
          </Action>
          <HRSection />
          <Features>
            <BulletList $hasBullets={false}>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Unlimited Boards
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Unlimited Job Applications
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Unlimited Resume Downloads
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Unlimited Events, Contacts
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Unlimited Tasks and Notes
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Kanban and Calendar Views
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Smart Tasks Manager
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Import Job Post from URL or description
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                AI generated Resumes
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                AI generated Cover letters
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                AI generated Emails
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                AI Assistant
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Daily and Weekly Email Reports
              </li>
              {/* <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Matching Job Applications - <i>Coming Soon</i>
              </li> */}
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                Instant Notifications
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Primary} />
                No adverts
              </li>
            </BulletList>
          </Features>
        </Column>
        <Column
          $hasSelectedPlan={hasSelectedPlan}
          onClick={() => handlePlanSelect(Plan.Free)}
          $isSelected={selectedPlan === Plan.Free}
        >
          <Header>
            <Title>
              <IconWrapper>
                <Lottie key="Collaborate" autoplay={true} animationData={rocketLottie} />
              </IconWrapper>
              Free
            </Title>
            <SubTitle>All the essentials to get you started</SubTitle>
          </Header>
          <HRSection />
          <Price>
            <PriceCurrency>£</PriceCurrency>
            <PriceAmount>0</PriceAmount> / month
          </Price>
          <Action>
            <SecondaryButton onClick={() => handlePlanSelect(Plan.Free)}>Start for free</SecondaryButton>
            <Legal>
              <CreditCardIcon size={0.8} />
              Try it out. No credit card required.
            </Legal>
          </Action>
          <HRSection />
          <Features>
            <BulletList $hasBullets={false}>
              <li>
                <CheckIcon size={1.3} color={Colors.Contrast} />
                Limited Boards (Only 3 active at a time)
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Contrast} />
                Limited Job Applications (Only 20 active per board)
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Contrast} />
                Resume Builder (no AI or download)
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Contrast} />
                Limited Events, Contacts
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Contrast} />
                Limited Tasks and Notes
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Contrast} />
                Kanban and Calendar Views
              </li>
              <li>
                <CheckIcon size={1.3} color={Colors.Contrast} />
                Limited adverts
              </li>
            </BulletList>
          </Features>
        </Column>
      </Columns>
    </Container>
  );
};

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
`;

const Column = styled.div<{ $isSelected?: boolean; $hasSelectedPlan: boolean }>`
  flex: 1 1 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 0.5rem;
  border-top: 32px solid ${Colors.White};
  outline: 1px solid ${Colors.Primary};
  box-shadow: 0px 0px 20px 3px ${Colors.Primary};
  cursor: pointer;

  ${({ $hasSelectedPlan }) => {
    if (!$hasSelectedPlan) {
      return css`
        &:nth-child(1) {
          border-top: 32px solid ${Colors.Primary};
        }

        &:nth-child(2) {
          border-top: 32px solid ${Colors.Contrast};
          box-shadow: 0px 0px 30px 7px ${Colors.Contrast};
          outline: 1px solid ${Colors.Contrast};
          &:before {
            content: 'Most Popular';
            display: block;
            font-size: 0.9rem;
            font-weight: 700;
            text-align: center;
            color: ${Colors.White};
            transform: translateY(-28px);
            height: 0;
          }
        }

        &:nth-child(3) {
          border-top: 32px solid ${Colors.White};
        }
      `;
    }
  }};

  ${({ $isSelected }) => {
    if ($isSelected) {
      return css`
        border-top: 32px solid ${Colors.Contrast};
        outline: 1px solid ${Colors.Contrast};
        &:before {
          content: 'Selected Plan';
          background-color: ${Colors.Contrast};
          display: block;
          font-size: 0.9rem;
          font-weight: 700;
          text-align: center;
          color: ${Colors.White};
          transform: translateY(-26px);
          height: 0;
        }
      `;
    }
  }};
`;

const Header = styled.div`
  height: 160px;
`;

const Title = styled.h3`
  padding: 1rem;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  color: ${Colors.PrimaryDarkest};
  display: flex;
  justify-content: center;
`;

const SubTitle = styled.h4`
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  font-weight: bolder;
  color: ${Colors.GreyLightest};
`;

const Price = styled.div`
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
`;

const PriceCurrency = styled.span`
  display: inline-block;
  font-size: 1.1rem;
  padding: 0.5rem;
  font-weight: 500;
  text-align: center;
  transform: translateY(-16px);
`;

const PriceAmount = styled.span`
  font-weight: 500;
  font-size: 3rem;
`;

const Action = styled.div`
  padding: 1rem;
  height: 124px;
`;

const Legal = styled.div`
  padding: 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  text-align: center;
`;

const Features = styled.div`
  padding: 1rem;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 52px;
  height: 52px;
  flex: 0 0 52px;
`;
